import { Box } from "@mui/material";

function DiscSvg({ profile }) {
  return (
    <Box width="400">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="400"
        width="400"
        fill="lightgrey"
        viewbox="0 0 100 100"
      >
        <circle cx="50%" cy="50%" r="50%" fill="lightgrey"></circle>
        <circle
          cx={profile.axis.x * 50 + 50 + "%"}
          cy={-profile.axis.y * 50 + 50 + "%"}
          r="2%"
          fill="red"
        ></circle>
        <line
          x1="50%"
          y1="50%"
          x2={profile.axis.x * 50 + 50 + "%"}
          y2={-(profile.axis.y * 50) + 50 + "%"}
          stroke="black"
        />

        <line x1="0" y1="50%" x2="100%" y2="50%" stroke="grey" />
        <line x1="50%" y1="0" x2="50%" y2="100%" stroke="grey" />

        <text x="25%" y="25%" class="small" fill="black">
          D
        </text>
        <text x="75%" y="25%" class="small" fill="black">
          I
        </text>
        <text x="25%" y="75%" class="small" fill="black">
          C
        </text>
        <text x="75%" y="75%" class="small" fill="black">
          S
        </text>
      </svg>
    </Box>
  );
}

export default DiscSvg;
