import { Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import DiscSvg from "../../components/Assessment/DiscSvg";
import axios from "axios";

function PersonalityProfile() {
  const [profile, setProfile] = useState({});
  const { keycloak } = useKeycloak();
  const [newstate, setNewState] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/assessment/disc/profile", {
        headers: { Authorization: "Bearer " + keycloak.token },
      })
      .then((response) => {
        setProfile(response.data);
      });
  }, []);

  return (
    <>
      <Typography variant="h3" pt={3}>
        Your Disc Profile{" "}
        <span data-testid="profile-style-computed">
          {profile.computed_style}
        </span>
      </Typography>
      {profile.axis !== undefined && (
        <Box sx={{ width: "100%" }}>
          <DiscSvg profile={profile}></DiscSvg>
        </Box>
      )}
    </>
  );
}

export default PersonalityProfile;
